.footer {
    width: 100%;
    padding: 2rem;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    white-space: nowrap;
}