.contact-window {
    margin-top: 80px;
    padding: 20px 12vw 20px 15vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-window h1 {
    color: #fff;
    font-size: 50px;
}

.contact-window p {
    font-size: 16px;
    letter-spacing: 1px;
    word-spacing: 2px;
    margin-bottom: 30px;
}

.contact-container {
    display: flex;
    align-items: flex-end;
    width: 100%;
}

.contact-wrapper-left {
    width: 450px;
    height: 100%;
    position: relative; /* Membuat posisi pseudo-element absolut */
    overflow: hidden; /* Pastikan elemen pseudo tidak melampaui batas gambar */
}

.contact-wrapper-left img {
    width: 100%;
    height: auto;
    display: block; /* Pastikan gambar menyesuaikan dengan container */
}

/* Tambahkan efek fade di bawah gambar */
.contact-wrapper-left img {
    position: relative;
    z-index: 0;
}

.contact-wrapper-left:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10%; /* Sesuaikan tinggi fade */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    z-index: 2; /* Pastikan efek fade berada di atas gambar */
    pointer-events: none; /* Agar efek fade tidak mengganggu interaksi */
}

.contact-wrapper-right {
    width: 50%;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-wrapper-right input, textarea {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 6px;
    margin-bottom: 25px;
    padding-left: 15px;
    font-size: 18px;
    width: 100%;
    transition: 0.3s ease-in-out;
    outline: none;
}

.contact-wrapper-right input {
    height: 45px;
}

.contact-wrapper-right input:focus, textarea:focus {
    border: 1px solid #fff;
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.4);
}

.contact-wrapper-right textarea {
    padding-top: 12px;
}



@media screen and (max-width: 1190px) {
    .contact-wrapper-left {
        max-width: 380px;
        width: 100%;
    }

    .contact-wrapper-right input {
        height: 40px;
        font-size: 16px;
        margin-bottom: 12px;
    }

    .contact-wrapper-right textarea {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .btn#submitBtn {
        margin-top: 0px;
        font-size: 16px;
        font-weight: 400;
    }
}


@media screen and (max-width: 945px), (max-height: 550px) {
    .contact-window {
        padding: 100px 18vw 20px 18vw;
        margin: 0;
    }

    .contact-window h1 {
        font-size: 40px;
    }

    .contact-window p {
        font-size: 14px;
    }

    .contact-wrapper-left {
        display: none;
    }

    .contact-wrapper-right {
        width: 100%;
        padding: 0;
    }

    .contact-wrapper-right form {
        padding-left: 0;
    }
}